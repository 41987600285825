import React, { Component } from "react";
import Pdf from "../../documents/Rosy-Recreational-Latham-Grand-Dealer-Fiberglass-Catalog-2022-.pdf";

class PDFDownload extends Component {
  render() {
    return (
      <div className="App">
        <a href={Pdf} target="_blank" rel="noreferrer">
          Download Pdf
        </a>
      </div>
    );
  }
}

export default PDFDownload;
