import React from "react";
import { Link } from "react-router-dom";
import {
  navigationLinks,
  navigation,
  products,
  services,
  footerSummary,
} from "./../../constants/links";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { FaSistrix } from "react-icons/fa";

import { Logo2 } from "./../../assets";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footerWrapper">
            <div className="logoCol">
              <div className="logo">
                <img src={Logo2} alt="logo" />
              </div>
            </div>
            <div className="menuCol">
              <div className="top">
                <div className="col companyDetails">
                  <h4>{footerSummary.title}</h4>
                  <p>{footerSummary.summary}</p>
                </div>
                <div className="col connect">
                  <div className="socials">
                    <Link
                      target="_blank"
                      to={
                        "https://instagram.com/rosyrecreational?igshid=MzRlODBiNWFlZA=="
                      }
                      className="icons"
                    >
                      <BsInstagram className="icon" />
                    </Link>
                    <Link
                      target="_blank"
                      to={
                        "https://www.facebook.com/rosyrecreational?mibextid=ZbWKwL"
                      }
                      className="icons"
                    >
                      <BsFacebook className="icon" />
                    </Link>
                  </div>
                  <div className="search-bar">
                    <form action="">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search here..."
                      />
                      <a className="searchButton" type="submit">
                        <FaSistrix className="searchIcon" />
                      </a>
                    </form>
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="navCol">
                  <div className="menu">
                    <div className="header">
                      <h3>navigation</h3>
                    </div>
                    {navigation.map((links) => {
                      return (
                        <Link
                          className="menu_item"
                          key={links.id}
                          to={links.url}
                        >
                          {links.name}
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div className="navCol">
                  <div className="menu">
                    <div className="header">
                      <h3>services</h3>
                    </div>
                    {services.map((links) => {
                      return (
                        <Link
                          className="menu_item"
                          key={links.id}
                          to={links.url}
                        >
                          {links.name}
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div className="navCol">
                  <div className="menu">
                    <div className="header">
                      <h3>products</h3>
                    </div>
                    {products.map((links) => {
                      return (
                        <Link
                          className="menu_item"
                          key={links.id}
                          to={links.url}
                        >
                          {links.name}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-menu">
            <div className="copyright">
              <div className="copy-name">
                &copy;
                <script>document.write(new Date().getFullYear());</script>
                {""} <Link to={"./"}> Rosy Recreational</Link> <br />
                <small>Designed & developed by </small> |
                <a
                  href="http://www.prisificagency.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {""} Prisific Agency
                </a>
              </div>
            </div>
            <div className="menu-items">
              {navigationLinks.map((links) => {
                return (
                  <Link className="menu_item" key={links.id} to={links.url}>
                    {links.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
