import React, { useEffect, useState } from "react";
import FloatingNavigation from "../floatingNav";
import { sportyFunContent } from "../../constants/data";
import "./sporty-fun.css";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";

const SportAndFun = () => {
  const [productsData, setProductsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "products"]{ sportyFunTab {..., image->}}`)
      .then((data) => {
        setProductsData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);
  return (
    <>
      <section className="features">
        <div className="content">
          <section className="textContentWrapper">
            <div className="header">
              {/* <h1>{productsData?.sportyFunTab?.sportyFunTitle}</h1>
              <h5>{productsData?.sportyFunTab?.sportyFunSubtitle}</h5> */}
              <h1>{sportyFunContent.title}</h1>
              <h5>{sportyFunContent.subTitle}</h5>
            </div>
            <div className="vidWrapper">
              <div
                className="ytVideo"
                data-aos="fade-right"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="200"
              >
                <iframe
                  // src={productsData?.sportyFunTab?.sportyFunYtLink}
                  src="https://www.youtube.com/embed/8u-tJU65hPY"
                  title="Game On in your Pool!  |  S.R.Smith Basketball Hoop"
                  frameborder="0"
                  allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  loading="lazy"
                ></iframe>
              </div>
              <div
                className="vidBkg"
                data-aos="fade-left"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="400"
              />
            </div>
            <div className="txtContent">
              {/* <p>{productsData?.sportyFunTab?.sportyFunDetails}</p> */}
              <p>{sportyFunContent.p1}</p>
            </div>
          </section>
        </div>
        <div className="floatingNav">
          <FloatingNavigation />
        </div>
      </section>
    </>
  );
};

export default SportAndFun;
