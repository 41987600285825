import {
  splash,
  booklet,
  banner,
  servePool,
  poolConcrete,
  poolFix,
  utility,
  steveImg,
  dasilvaImg,
  teamAvatarImg,
  mapImg,
  poolBgImg,
  silverAwardImg,
  bbbImg,
  councilImg,
  goldAwardImg,
  awardsImg,
  topCAwardsImg,
  emailIcon,
  callIcon,
  mapIcon,
  exclamationIcon,
  img1,
  img2,
  img3,
  img4,
  img5,
  img16,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  joaoImg,
  jakeImg,
  mitchellImg,
  matthewImg,
} from "../assets";

export const testimonies = {
  testimonyHeader: "what people say !  ",
  testimonyTitle: "testimonials",
  testimonials: [
    {
      id: "0",
      testimony:
        "A small group of people with great passion for what they do. Dealt with them for two weeks including the team who digged the pool, the concrete group, basically people in and out for two weeks. Now we get to tell everyone to come and enjoy our pool, but let’s not forget the team that allowed this to happen. I feel privileged just because I got to meet that face behind all of this. Thank you all",
      testifier: "P.V.  ",
      role: "PV",
    },
    {
      id: "1",
      testimony: " Outstanding service and quality!",
      testifier: "Cambridge. -  Client",
      role: "client",
    },
    {
      id: "2",
      testimony:
        "“Can-Do” Attitude \n We recently had a pool installed by Rosy, our installation was really challenging based on the limited access that we had available when a neighbour decided not to let us access through the wider side of the lot. \n Rosy has a great “can-do” attitude and worked with us modify the pool so we could still install it this year. We were really impressed with the crew and would act as a reference to anyone looking for a quality installation from a quality individual.",
      testifier: "Eric Karp.",
      role: " Kitchener, Ontario",
    },
    {
      id: "3",
      testimony:
        "Extension Of Living Area \n Thanks to Steve and the team at Rosy Recreational, our backyard has been transformed into an extension of our living area. On a project of this size and scale, challenges are certain to come up. We would like to thank Steve for testifierally taking ownership of them and showing a true commitment to customer satisfaction.",
      testifier: "Rodney & Susan Dusick. ",
      role: " Cambridge, Ontario",
    },
    {
      id: "4",
      testimony:
        "Backyard Of Our Dreams \n We can not thank Rosy &amp; his team enough for the great work they did on our pool. Rosy was able to come in and help us out when our original pool contractor did not work out. Even when he was super busy constructing other pools he made the time to complete ours and give us a backyard of our dreams. The workmanship and dedication is amazing and we would definitely recommend him &amp; his team. Thanks guys for a wonderful job.",
      testifier: "Gary & Crystal Pereira. ",
      role: " Cambridge, Ontario",
    },
    {
      id: "5",
      testimony:
        "Amazed and Pleased \n A big thanks to Steve Vicente and the crew at Rosy Recreational for the outstanding customer service that they have provided to us after purchasing our 16 x 32 in ground pool in 2009. We were, and still are, amazed and pleased by Rosy’s excellent customer service before, during and after the installation of our pool. \n Outstanding service and quality!",
      testifier: "Tom and Lori Perkes. ",
      role: " Cambridge, Ontario",
    },
    {
      id: "6",
      testimony:
        " We love our Rosy Recreational pool – hands down a well planned, well executed project. This was our first pool project and Steve was extremely helpful during the planning process – by offering his guidance and professional opinion, we made educated decisions and have absolutely zero regrets. The entire Rosy Rec crew was professional, considerate and well-organized. We could not be more pleased overall.",
      testifier: "Ed and Karin Arnold  ",
      role: " Rockwood, Ontario",
    },
    {
      id: "7",
      testimony:
        "Love The Pool. Thanks again for all the hard work and for putting up with us from planning through completion! Our girls absolutely love the pool and the 4 year old has already learned how to swim unassisted within a couple of weeks! Here’s to a few more warm weeks ahead!",
      testifier: "HELENA & DARRYL KEIRSTEAD  ",
      role: " Kitchener Ontario",
    },
    // {
    //   id: "8",
    //   testimony: "Trust wanted to say / was on your website and I fnally filled out a customer testimonial Sorry it has taken this long Justwant to sav that I saw your pickup truck at my neighbours house today I just wanted to saverther need proofor how good vour works ore them mv adoress and suproa alacty to show them the gream oasis in our backvard Thanks again for a job well done. We LOVE Our pool, all thanks to Rosy Recreational As a note I saw on Facebook our former tenants new pool WOW Great job rosy!!",
    //   testifier: "Helena & Darryl Keirstead. -  Kitchener, Ontario",

    // },
    {
      id: "9",
      testimony:
        "It has been a year since we have had Rosy Recreational install our 16 x 32 inground pool. I waited this long to send a letter, because I wanted to make sure the workmanship and customer service was up to par. I was wrong! Steve and his team have been fantastic and have gone beyond the mark! If I have any questions, he got back to me and assisted in making sure all is well. My family and I are loving the pool! Thank you Steve and your team!",
      testifier: "William – Cambridge.  ",
      role: " William - Cambridge",
    },
    {
      id: "10",
      testimony:
        "Thank-you, one and all!! Y’all did a great job here at our house – nice work!",
      testifier: "Kitchener.",
      role: " Client ",
    },
    {
      id: "11",
      testimony:
        "When family and friends visit they can’t believe the transformation of the backyard.",
      testifier: "Cambridge 1.  ",
      role: " Client ",
    },
    {
      id: "12",
      testimony:
        "I couldn’t recommend Rosy Recreational more for your backyard pool installation project.",
      testifier: "Cambridge. ",
      role: " Client ",
    },
    {
      id: "13",
      testimony:
        "Thank-you, one and all!! Y’all did a great job here at our house – nice work! \n Brent Doerner – Satisfied Customer & Cinematographer \n As everyone else, we did our home work, talked to different people, some have pools others were looking just like us. \n And finally, we made the call to Steve Vicente (Rosy). In my modest opinion, Rosy stands for: \n Remarkable \n  Outstanding \n Sincerity \n Youth.",
      testifier: "The Oasis Project.",
      role: " Project ",
    },
    {
      id: "14",
      testimony:
        "'Service Second To None' \n Rosy Recreational’s design ideas and workmanship have been essential to enhancing the look of our home. The crew was very professional, well-organized, and delivered what they promised on time. Before the project began we didn’t know exactly where we wanted to end up, so Rosy provided us with numerous options.",
      testifier: "Skye & Stacy Buchanan.  ",
      role: " Satisfied Customers ",
    },
    {
      id: "15",
      testimony:
        "I just wanted to say I was on your website and I finally filled out a customer testimonial. Sorry it has taken this long. Just want to say that I saw your pickup truck at my neighbours house today. I just wanted to say if they need proof of how good your work is, give them my address and I will be gladly to show them the dream oasis in our backyard. \n Thanks again for a job well done. We LOVE Our pool, all thanks to Rosy Recreational. As a note, I saw on Facebook our former tenants new pool. WOW Great job rosy!!!",
      testifier: "Analia – Cambridge.",
      role: " Analia – Cambridge ",
    },
  ],
};

export const fiberGlassContent = {
  title: "FIBERGLASS IS OUR SPECIALITY",
  subTitle:
    "Rosy Recreational has been chosen to be a Grand Dealer of latham Fiberglass Pools",
  p1: "A swimming pool—the centerpiece of your backyard—is always a big investment. You want to be comfortable with your choices and you should be delighted by the results.",
  p2: "Latham is the largest manufacturer of fabricated pools in the world, with over six decades of experience at the center of the backyard lifestyle. Every day, thousands of families in North America, Europe and Australia enjoy Latham pools and they want you to have the knowledge and confidence to select the one that is right for you. Latham is The Pool Company.",
  p3: "Proven manufacturing processes and premium materials make Latham pools virtually maintenance-free, giving you more time to enjoy the center of your backyard living. They use top quality ceramic, resin, gel coat and fiberglass materials.",
  p4: "Latham Pools have the strongest, longest warranties in the industry. They can back them better because they know they are built better.",
  p5: "All Latham Pool Products are proudly manufactured in our multiple high-tech facilities throughout the U.S. and Canada. They build close to home so we can monitor every process for quality, and ensure prompt delivery to their customers",
  whyTitle: "Why Fiberglass?",
  specialityImg: splash,
  whyList: [
    "Trends Moving Towards Fibreglass.",
    "Aesthetics Rivalling Vinyl with New Shape & Colour Options.",
    "Lower Maintenance.",
    "Lower Long-term Costs.",
    "Fewer Chemicals Needed = Eco-friendlier.",
    "Less Electrical Usage.",
    "Better Insulation.",
    "Better Longevity & Durability.",
    "Children & Dog-friendly (no puncture possibilities.)",
    "Resale Value (Fiberglass is viewed as a long-term structure).",
    "Non-porous Surface Resists Algae Growth.",
    "Stain Resistant.",
    "Better Warranty on the Shell.",
    "Faster Installation time.",
    "Non-abrasive Material.",
    "Many Designs Include Built-in Steps = No Additional Costs.",
  ],
  banner: banner,
  whyBooklet: booklet,
};

export const homeContent = {
  aboutHeader: "about us",
  aboutTitle: "ROSY RECREATIONAL",
  aboutDescription:
    "Experience the ultimate backyard transformation with Rosy Recreational. Our team of skilled professionals is committed to delivering inspired designs and unparalleled attention to detail, ensuring your pool project is nothing short of exceptional \n\n Trust Rosy Recreational to create a backyard paradise that seamlessly blends flawless design, functionality, and style. As a multiple award-winning company, we offer clean, timeless designs that elevate your pool to center stage and make it the envy of your neighborhood. Our team of experts combines innovative designs with meticulous attention to detail to bring your vision to life, ensuring that every aspect of your pool project exceeds your expectations. With Rosy Recreational, you can enjoy a breathtaking outdoor oasis that perfectly reflects your unique style and taste. \n\n Rosy Recreational can bring together flawless design, functionality, and style to make your very own backyard paradise. A multiple award-winning company with clean timeless designs that will put your pool at centre stage and be the envy of all your neighbours.",

  services: [
    {
      id: "1",
      serviceTitle: "design & build",
      serviceDescription:
        "With a progressive design approach we create pools that offer beauty, personalized function, and superior structural integrity for years to come using the highest end materials on the market.",
      url: "/services?tab=2",
    },
    {
      id: "2",
      serviceTitle: "utility",
      serviceDescription:
        "We have a team of specialists of assist you every step of the way.  Starting with customer service & support to the best onsite team to give you quality in build, electrical, plumbing and concreting maximizing the result and customer satisfaction on every one of our projects.",
      url: "/services?tab=3",
    },
    {
      id: "3",
      serviceTitle: "concrete",
      serviceDescription:
        "Our relationship with SGConcreteworks goes back over several years of delivering customization and top quality to meet each customer’s needs to reflect their vision and needs.",
      url: "/services?tab=1",
    },
    {
      id: "4",
      serviceTitle: "FINISHING TOUCHES",
      serviceDescription:
        "We create pools that offer beauty, personalized function, and superior structural integrity for years to come with the highest end material on the market.",
      url: "/services?tab=0",
    },
  ],

  counter: [
    {
      id: 1,
      number: "16",
      subTitle: "years in business",
    },
    {
      id: 2,
      number: "704",
      subTitle: "pool installed",
    },
    {
      id: 3,
      number: "10,000 +",
      subTitle: " Dreams Come True",
    },
    // {
    //   id: 4,
    //   number: "248105",
    //   subTitle: "hours",
    // },
  ],

  questionsHeader: "questions?",
  qParagraph:
    "Your satisfaction is our priority, and we look forward to providing you with exceptional services. Don't hesitate to get in touch with us",
  address: "Backyard Resorts \n 20 Sheldon Dr. \n Cambridge, Ontario ",
  email: "steve@rosyrec.com",
  phone: "519 239-5341 (Steve)",
};

export const waterFeaturesContent = {
  waterFeaturesTitle: "water features",
  waterFeaturesDetails:
    "Whether with simple deck jets, bubblers or soothing waterfall, you can enhance your pool with that extra zing. \n \n Adding a waterfall to your swimming pool is a great way to increase your pool experience. Waterfalls transform your pool from a simple design into a luxury atmosphere, like having a Five Star resort feeling right in your backyard. \n\n It truly is that “wow” factor that you have always wanted. The visual beauty, serene feeling, and practical benefits make a waterfall an appealing option for any homeowner looking to build a new pool.",
};

export const sportyFunContent = {
  title: "sporty & fun",
  subTitle: "improve your lifestyle",

  p1: "Have even more fun in your pool with basketball or volleyball nets anchored into your pool deck.",
};

export const haywardContent = {
  title: "hayward expert line",
  haywardCards: [
    {
      id: 1,
      title: "variable speed pumps",
      body: "While the Super 2 system is a standard high efficiency performance pump, the Tristar Variable Speed Pump (VSP) offers better long term energy costs and a faster payback",
      url: "https://www.youtube.com/watch?v=yuZDYJXMQow",
      delay: 100,
    },
    {
      id: 2,
      title: "cartridge filters",
      body: "While the Top Mount Sand Filter is self-cleaning and offers performance, value & dependability, The Swim Clear Quad Cluster Cartridge Filter is the industry leader in hydraulic performance which extends the time between maintenance",
      url: "https://www.youtube.com/watch?v=lBQ3O4hsMs0",
      delay: 300,
    },
    {
      id: 3,
      title: "hydrorite uvo3",
      body: "UV Ozone Sanitization System. The ultimate in water purification.UV + OZONE + ADVANCED OXIDATION = 100% PURE WATER",
      url: "https://www.youtube.com/watch?v=VP1Qj-8ycis",
      delay: 400,
    },
    {
      id: 4,
      title: "omni logic automation",
      body: "Fully customizable controls for every part on your pool. Can all be run by your phone or iPod. Any upgrades downloadable just like an app. Easy to use.",
      url: "https://www.youtube.com/watch?v=y35MqTC084Q",
      delay: 600,
    },
  ],
  formHeader: "hayward pool cleaner repair",
  formTitle: "we now offer",
  p1: "Below is a link to a downloadable/editable repair form. Once completed please print, attach a copy of your receipt and drop your cleaner off at Backyard Resorts in Cambridge, ON. The address is 20 Sheldon Drive. 519-622-1445.",
  url: "/hayward-form",
  delay: 800,
};

export const winterCoversContent = {
  title: "winter covers",
  p1: "All our pool covers are manufactured to your specific pool size and shape.",
  p2: "A Performance Safety Cover protects both your pool and your family. A properly installed Performance Safety Cover will help prevent accidents and injuries to your children, pets and wildlife.",
  listTitle: "cleardeck system: summer cover storage unit",
  listSub: "ClearDeck in-ground solar blanket roller",
  listItem: [
    "Does not detract from the beauty of surroundings",
    "Easy one person operation",
    "Sleek and Compact and sits flush with deck",
    "Blanket lasts longer since it is protected from UV rays when retracted.",
    "No-Trip hazard",
    "Year round blanket storage",
  ],
};

export const servicesContent = {
  finishingTouchesTab: {
    ftTitle: "finishing Touches",
    ftDetails:
      "We create pools that offer beauty, personalized function, and superior structural integrity for years to come with the highest end material on the market. One of the finest attributes that stands out about our business is the polished quality and remarkable know-how as design consultants.",
    image: servePool,
    forYearsTitle: "FOR YEARS TO COME",
    forYearsDetails:
      "We build fibreglass swimming pools. As a pool builder, we apply our skill and specialized knowledge to create a better pool for you and your family. With a progressive design approach and building practices that go beyond the industry standard, we create pools that offer beauty, personalized function, and superior structural integrity for years to come",
    dreamToRealityTitle: "FROM DREAM TO REALITY",
    dreamToRealityDetails:
      "Rosy Recreational brings the high art of pool design and creativity to real-world practicality that is specifically tailor-made for you! That has to make an impression, because regardless of your property’s size and dimensions, the team will devise a plan for your property that works for you and that you will love.",
  },

  concreteTab: {
    concreteTitle: "LANDSCAPING & CONCRETE",
    concreteDetails:
      "Our relationship with SGConcreteworks goes back over several years. They specialize in decorative concrete, with options of finish and colourant. Every job is customized, and suited to meet our customers’ needs, & reflect their vision.     ",
    image: poolConcrete,
    foundationTitle: "FOUNDATION",
    foundationDetails:
      "One of the pillars of the business is professional patio installation, the perfect complement to all of the services and projects. A great patio begins with a solid foundation. Concrete is much more than a bag of powdered stone mixed with just the right amount of water. With the exciting new coloring and texturing technologies now at work, concrete can create a cohesive look across your entire property.",
    landscapingTitle: "LANDSCAPING",
    landscapingDetails:
      "From driveways and walkways coordinated to match your home's exterior design and colours, to patios and retaining walls that blend naturally into your landscaping designs, concrete installation can be elevated to an art form in the right hands of our professional company",
  },

  designBuildTab: {
    designBuildTitle: "DESIGN & BUILD",
    designBuildDetails:
      "As a pool builder, we apply our skill and specialized knowledge to create a better pool for you and your family. With a progressive design approach and building practices that go beyond the industry standard, we create pools that offer beauty, personalized function, and superior structural integrity for years to come. \n \n   Rosy Recreational has proven results for setting exceptional standards, in planning, service, installation and project safety. We have experience that gives us a competitive advantage over others in our field. ",

    image: poolFix,
    pmTitle: "PROJECT MANAGEMENT ",
    pmDetails:
      "We design and construct fibreglass swimming pools. As a pool builder, we apply our skill and specialized knowledge to create a the best pool for you and your families needs. With a progressive design approach and building practices that go beyond the industry standard, we create pools that offer beauty, personalized function, and superior structural integrity for years to come.      ",
    costTitle: "REPORTING AND COST CONTROL    ",
    costDetails:
      "We do only what we are great at. If we tackle a project you can be 100% sure that it will be delivered right on time, and top in class.",
  },

  utilityTab: {
    utilityTitle: "UTILITY",
    utilityDetails:
      "We have a team of specialists and subcontrators top in their feilds to maximize the result of each project. The company is prepared for installation and implementation, each step of the way.      ",

    image: utility,
    dsTitle: "DESIGN & SUITED ",
    dsDetails:
      "Everything is uniquely designed, layed out, and incorporated in your new exterior yard renovation. In short, if you want a new yard renovation – the sky’s the limit.    ",
    knowTitle: "WHAT YOU NEED TO KNOW",
    knowDetails:
      "Included in the Rosy Recreational “can-do” résumé of expertise are in-ground pools of every shape and size that are designed and suited perfectly to your home. Aswell as waterfalls and fountains, concrete work, pool plumbing, electrical, with access to top landscapers.",
  },
};

export const ourStoryContent = {
  header: "All About Rosy Recreational",
  headerTitle: "OUR STORY",
  paragraphOne:
    "Rosy Recreational specializes in the installation of Fibreglass swimming pools. With each new challenge, we bring years of experience and a tradition of excellence.",
  paragraphTwo:
    "We design backyard your backyard oasis with fibreglass swimming pools. As a pool builder, we apply our skill and specialized knowledge to create the best pool for you and your family. With a progressive design approach and building practices that go beyond the industry standard, we create pools that offer beauty, personalized function, and superior structural integrity for years to come.",
  paragraphThree:
    "Please note that our specialty is Inground Fibreglass and we do not install or sell above ground or vinyl swimming pools.",
  storyVid: "https://player.vimeo.com/video/210535913?h=f7070a5037",
  storyVidCards: [
    {
      id: 1,
      title: "EXPERIENCE",
      details:
        "As a pool builder, we apply our skill and specialized knowledge to create the best pool for you and your family.",
    },
    {
      id: 2,
      title: "QUALITY",
      details:
        "we create pools that offer beauty, personalized function, and superior structural integrity for years to come.",
    },
    {
      id: 3,
      title: "TRUSTED",
      details:
        "With each new challenge, we bring years of experience and a tradition of excellence.",
    },
  ],
  awardsHeader: "Trust Us !  ",
  awardsTitle: "AWARDS & CERTIFICATIONS",
  awardsImg: awardsImg,
  goldAwardsImg: goldAwardImg,
  goldAwards: [
    {
      id: 1,
      title: "GOLD AWARD",
      details:
        "2011 Readers Choice Awards for Best Business Service for a Pool Company",
    },
    {
      id: 2,
      title: "GOLD AWARD",
      details:
        "2012 Readers Choice Awards for Best Business Service for a Pool Company",
    },
    {
      id: 3,
      title: "GOLD AWARD",
      details:
        "2013 Readers Choice Awards for Best Business Service for a Pool Company",
    },
    {
      id: 4,
      title: "GOLD AWARD",
      details:
        "2014 Readers Choice Awards for Best Business Service for a Pool Company",
    },
    {
      id: 5,
      title: "GOLD AWARD",
      details: "2017 Readers Choice awards",
    },
    {
      id: 6,
      title: "GOLD AWARD",
      details:
        "2018 Readers Choice Awards for Best Business Service for a Pool Company",
    },
    {
      id: 7,
      title: "GOLD AWARD",
      details:
        "2019 Readers Choice Awards for Best Business Service for a Pool Company",
    },
    {
      id: 8,
      title: "GOLD AWARD",
      details:
        "2020 Readers Choice Awards for Best Business Service for a Pool Company",
    },
    {
      id: 9,
      title: "GOLD AWARD",
      details:
        "2021 Readers Choice Awards for Best Business Service for a Pool Company",
    },
    {
      id: 10,
      title: "GOLD AWARD",
      details:
        "2022 Readers Choice Awards for Best Business Service for a Pool Company",
    },
  ],
  bbbAcreditationImg: bbbImg,
  poolCouncilImg: councilImg,
  silverAwardsImg: silverAwardImg,
  silverAwards: [
    {
      id: 1,
      title: "SILVER AWARD",
      details:
        "2014 Design & Construction Awards / Outdoor Vinyl Pool – Over 700 sq.ft (no Water Features) Pool & Hot Tub Council of Canada",
    },
    {
      id: 2,
      title: "BRONZE AWARD",
      details:
        " 2015 Design & Construction Awards / Outdoor Vinyl Pool – Over 700 sq.ft (with Water Features) Pool & Hot Tub Council of Canada",
    },
    {
      id: 3,
      title: "JOSH NELSON",
      details: "Courage Award to Steve Vicente & the Rosy Recreational Team",
    },
    {
      id: 4,
      title: "The Coping Centre",
      details:
        "Appreciation to Rosy Recreational Swimming Pools and Concrete 2017",
    },
  ],

  charitiesAndOrganizationHeader: "CHARITIES & ORGANIZATIONS",
  charitiesAndOrganizationSubHeader: "Affiliated & Sponsorships",
  affiliateList: [
    { sponsor: "Hespeler Peewee Hockey Level A" },
    { sponsor: "Kitchener JR Rangers Minor Peewee" },
    { sponsor: "The Coping Centre" },
    { sponsor: "Work for a Cure" },
    { sponsor: "Kids can Play" },
    { sponsor: "Shamrocks" },
    { sponsor: "Hockey" },
  ],
  topChoiceAwardsImg: topCAwardsImg,
  topChoiceAwards: [
    {
      id: 1,
      title: "TOP CHOICE  AWARD",
      details: "2019 Top Pool Builder- Kitchener Waterloo",
    },
    {
      id: 2,
      title: "",
      details: " 2020 Top Pool Builder- Kitchener Waterloo",
    },
    {
      id: 3,
      title: "",
      details: "2021 Top Pool Builder- Kitchener Waterloo",
    },
    {
      id: 4,
      title: "",
      details: " 2022 Top Pool Builder- Kitchener Waterloo",
    },
    {
      id: 5,
      title: "",
      details: "Voted 2023 Top Swimming Pool Company - Kitchener Waterloo",
    },
    {
      id: 6,
      title: "",
      details: "2024 Top Choice Award",
    },
  ],

  qualityFirstTitle: "QUALITY COMES FIRST",
  qualityFirstDescription:
    "Trust Rosy Recreational to bring your backyard makeover to reality from start to finish. A multiple award winning company with clean timeless designs that will put your pool at center stage.",
  qualityImg: poolBgImg,

  teamHeader: "Come meet our",
  teamTitle: "AWESOME TEAM",
  teamMembers: [
    {
      id: 1,
      img: steveImg,
      name: "STEVE VICENTE(A.K.A ROSY) ",
      position: "  Owner",
    },
    // {
    //   id: 2,
    //   img: dasilvaImg,
    //   name: "EVAN DASILVA",
    //   position: "Supervisor",
    // },
    {
      id: 3,
      img: joaoImg,
      name: "JOAO SOUSA",
      position: "",
    },
    // {
    //   id: 4,
    //   img: jakeImg,
    //   name: "Jake",
    //   position: "",
    // },
    {
      id: 5,
      img: mitchellImg,
      name: "Mitch",
      position: "",
    },
    {
      id: 6,
      img: matthewImg,
      name: "Matthew",
      position: "",
    },
  ],

  whereWeWorkHeader: "This is where",
  whereWeWorkTitle: "WE WORK",
  whereWeWorkImg: mapImg,
};

export const contactUsContent = {
  subHeaderTitle: "We want to hear from you!",
  headerTitle: "contact us",
  areaSubTitle: "map",
  areaTitle: "service area",
  contactInfo: [
    {
      id: 1,
      title: "email us",
      paragraph: "steve@rosyrec.com",
      icon: emailIcon,
    },
    {
      id: 2,
      title: "call us",
      paragraph: "519 239-5341 (Steve)",
      icon: callIcon,
    },
    {
      id: 3,
      title: "visit us",
      paragraph: "Backyard Resorts \n 20 Sheldon Dr. \n Cambridge, Ontario",
      icon: mapIcon,
    },
    {
      id: 4,
      title: "",
      paragraph:
        "Please check your spam folder if you haven't received a response within 2 business days",
      icon: exclamationIcon,
      class: "blue",
    },
  ],
};

export const galleryImages = [
  {
    src: img1,
  },
  {
    src: img2,
  },
  {
    src: img3,
  },
  {
    src: img4,
  },
  {
    src: img5,
  },
  {
    src: img6,
  },
  {
    src: img7,
  },
  {
    src: img8,
  },
  {
    src: img9,
  },
  {
    src: img10,
  },
  {
    src: img11,
  },
  {
    src: img12,
  },
  {
    src: img13,
  },
  {
    src: img14,
  },
  {
    src: img15,
  },
  {
    src: img16,
  },
  {
    src: img17,
  },
  {
    src: img18,
  },
  {
    src: img19,
  },
  {
    src: img20,
  },
  {
    src: img21,
  },
  {
    src: img22,
  },
  {
    src: img23,
  },
  {
    src: img24,
  },
  {
    src: img25,
  },
  {
    src: img26,
  },
  {
    src: img27,
  },
  {
    src: img28,
  },
  {
    src: img29,
  },
  {
    src: img30,
  },
  {
    src: img31,
  },
  {
    src: img32,
  },
  {
    src: img33,
  },
  {
    src: img34,
  },
  {
    src: img35,
  },
  {
    src: img36,
  },
  {
    src: img37,
  },
  {
    src: img38,
  },
  {
    src: img39,
  },
  {
    src: img40,
  },
  {
    src: img41,
  },
  {
    src: img42,
  },
];
