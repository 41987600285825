import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./story-services.css";
import { ourStoryContent } from "./../../constants/data";
import { Link } from "react-router-dom";
import {
  Img,
  InfiniteHorizontalScroll,
  ScrollToTop,
  Testimonials,
} from "../../components";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";

const OurStoryServices = () => {
  const [aboutData, setAboutData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"]{...,  awardsBanner->,  qualityFirstImage->, whereWeWorkImage->,  teamMembers[] {..., image->}}`
      )
      .then((data) => {
        setAboutData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);

    sanityClient
      .fetch("*[_type == 'gallery']")
      .then((data) => {
        console.log("gallery data: ", data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("about data: ", aboutData);
  }, [aboutData]);

  return (
    <>
      <Helmet>
        <title>Our Story - Rosy Recreational Swimming Pool Construction</title>
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Our Story - Rosy Recreational Swimming Pool Construction"
        />
        <meta
          property="og:url"
          content="https://rosyrec.com/our-story-services"
        />
        <meta
          property="og:site_name"
          content="Rosy Recreational Swimming Pool Construction"
        />
      </Helmet>
      <section className="sub-hero story-services">
        <div className="container">
          <div className="heroLabel">
            <h1>about us</h1>
          </div>
        </div>
      </section>
      <section className="ourStory">
        <div className="container">
          <div className="col">
            <div className="content">
              <div className="header">
                {/* <h5>{aboutData?.title}</h5>
                <h1>{aboutData?.headerTitle}</h1> */}
                <h5>{ourStoryContent.header}</h5>
                <h1>{ourStoryContent.headerTitle}</h1>
              </div>
              <div className="txt">
                <p className="bold">{ourStoryContent.paragraphOne}</p>
                <p>{ourStoryContent.paragraphTwo}</p>
                <p className="blueColor">{ourStoryContent.paragraphThree}</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="content">
              <div className="top">
                {/* <iframe
                  title="vimeo-player"
                  src={aboutData?.vimeoLink}
                  frameborder="0"
                  allowfullscreen
                  loading="lazy"
                ></iframe> */}
                <iframe
                  title="vimeo-player"
                  src={ourStoryContent.storyVid}
                  frameborder="0"
                  allowfullscreen
                  loading="lazy"
                ></iframe>
              </div>
              <div className="bottom">
                <div className="cards">
                  {/* {aboutData?.aboutList?.map((item, index) => (
                    <div className="card" key={"listTitle#" + index}>
                      <h3>{item.listTitle}</h3>
                      <p>{item.listDescription}</p>
                    </div>
                  ))} */}
                  {ourStoryContent.storyVidCards.map((item, index) => (
                    <div className="card" key={"listTitle#" + index}>
                      <h3>{item.title}</h3>
                      <p>{item.details}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="trust">
        <div className="container">
          <div className="content">
            <div className="header">
              <h5>{ourStoryContent.awardsHeader}</h5>
              <h1>{ourStoryContent.awardsTitle}</h1>
            </div>
            <div className="imgHolder">
              <Img
                // src={urlFor(aboutData?.awardsBanner?.image)}
                src={ourStoryContent.awardsImg}
                alt={aboutData?.awardsBanner?.image.alt}
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="awards">
        <div className="container">
          <div className="awardRow topChoice">
            <div className="colImg">
              <Img
                src={ourStoryContent.goldAwardsImg}
                alt="gold winner"
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
                loading="lazy"
              />
            </div>
            <div className="colList">
              {ourStoryContent.goldAwards.map((award, index) => (
                <div className="awardListItem" key={"awardWon#" + index}>
                  <h3>{award.title}</h3>
                  <p>{award.details}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="awardRow topChoice">
            <div className="colImg">
              <Img
                src={ourStoryContent.topChoiceAwardsImg}
                alt="top choice awards"
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
                loading="lazy"
              />
            </div>
            <div className="colList">
              {/* {aboutData?.topChoiceAwards?.map((award, index) => (
                <div className="awardListItem" key={"awardWon#" + index}>
                  <h3>TOP CHOICE AWARD</h3>
                  <p>{award.awardWon}</p>
                </div>
              ))} */}
              {ourStoryContent.topChoiceAwards.map((award, index) => (
                <div className="awardListItem" key={"awardWon#" + index}>
                  <h3>TOP CHOICE AWARD</h3>
                  <p>{award.details}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="awardRow topChoice">
            <div className="colImg">
              <Img
                src={ourStoryContent.silverAwardsImg}
                alt="award winner"
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
                loading="lazy"
              />
            </div>
            <div className="colList">
              {ourStoryContent.silverAwards.map((award, index) => (
                <div className="awardListItem" key={"awardName#" + index}>
                  <h3>{award.title}</h3>
                  <p>{award.details}</p>
                </div>
              ))}
            </div>
          </div>
          <InfiniteHorizontalScroll />
          <div className="accreditation">
            <div className="hor">
              <div className="imagesWrapper">
                <Link
                  to={
                    "https://www.bbb.org/ca/on/cambridge/profile/pool-contractors/rosy-recreational-inc-0107-1241532#sealclick"
                  }
                >
                  <Img
                    src={ourStoryContent.bbbAcreditationImg}
                    alt="BBB Rating"
                    hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                    height={"100%"}
                    width={"100%"}
                  />
                </Link>
                <Img
                  src={ourStoryContent.poolCouncilImg}
                  alt="pool hot council member"
                  hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                  height={"100%"}
                  width={"100%"}
                  loading="lazy"
                />
              </div>
            </div>
            <div className="hor">
              <div className=" qualityCol">
                <div className="content quality">
                  <div className="header">
                    <h3>{ourStoryContent.charitiesAndOrganizationHeader}</h3>
                    <h5>{ourStoryContent.charitiesAndOrganizationSubHeader}</h5>
                  </div>
                  <div className="txt">
                    <ul>
                      {/* {aboutData?.affiliates?.map((affiliate, index) => (
                        <li key={"sponsor#" + index}>{affiliate.sponsor}</li>
                      ))} */}
                      {ourStoryContent.affiliateList.map((affiliate, index) => (
                        <li key={"sponsor#" + index}>{affiliate.sponsor}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="qualitySection">
        <div className="qualityImg">
          <div className="img">
            <Img
              //  src={urlFor(aboutData?.qualityFirstImage?.image)}
              src={ourStoryContent.qualityImg}
              alt={aboutData?.qualityFirstImage?.image.alt}
              hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
              height={"100%"}
              width={"100%"}
              loading="lazy"
            />
          </div>
        </div>
        <div className="textContent">
          <div className="txt">
            {/* <h2>{aboutData?.qualityFirstTitle}</h2>
            <p>{aboutData?.qualityFirstDescription}</p> */}
            <h2>{ourStoryContent.qualityFirstTitle}</h2>
            <p>{ourStoryContent.qualityFirstDescription}</p>
          </div>
        </div>
      </section>
      <section className="testimoniesSection">
        <div className="container">
          <Testimonials />
        </div>
      </section>
      <section className="ourTeam">
        <div className="container">
          <div className="content">
            <div className="header">
              <h5>{ourStoryContent.teamHeader}</h5>
              <h1>{ourStoryContent.teamTitle}</h1>
            </div>

            <div className="teamCards">
              {/* {aboutData?.teamMembers?.map((member, index) => (
                <div className="card" key={"members#" + index}>
                  <div className="avatar">
                    <Img
                      src={urlFor(member?.image.image)}
                      alt={member?.image.image.alt}
                      hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                      height={"100%"}
                      width={"100%"}
                    />
                  </div>
                  <div className="details">
                    <h3 className="name">{member.name}</h3>
                    <p className="position">{member.title}</p>
                  </div>
                </div>
              ))} */}
              {ourStoryContent.teamMembers.map((member, index) => (
                <div className="card" key={"members#" + index}>
                  <div className="avatar">
                    <Img
                      src={member.img}
                      alt={member.name}
                      hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                      height={"100%"}
                      width={"100%"}
                      loading="lazy"
                    />
                  </div>
                  <div className="details">
                    <h3 className="name">{member.name}</h3>
                    <p className="position">{member.position}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="whereWeWork">
        <div className="container">
          <div className="content">
            <div className="header">
              <h5>{ourStoryContent.whereWeWorkHeader}</h5>
              <h1>{ourStoryContent.whereWeWorkTitle}</h1>
            </div>
            <div className="map">
              <Img
                // src={urlFor(aboutData?.whereWeWorkImage?.image)}
                src={ourStoryContent.whereWeWorkImg}
                alt={aboutData?.whereWeWorkImage?.image.alt}
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
              />
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default OurStoryServices;
