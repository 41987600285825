import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Mousewheel } from "swiper";
import "./new-swipe.css";
import "swiper/css";
// import "swiper/css/navigation";
import { useState } from "react";

import {
  thumbsImage1,
  thumbsImage2,
  thumbsImage3,
  thumbsImage4,
  thumbsImage5,
  thumbsImage6,
  thumbsImage7,
} from "../../assets";
import Img from "../image";

const slides = [
  thumbsImage1,
  thumbsImage2,
  thumbsImage3,
  thumbsImage4,
  thumbsImage5,
  thumbsImage6,
  thumbsImage7,
];

export default function NewSwipe() {
  const [imagesNavSlider, setImagesNavSlider] = useState(null);
  return (
    <div className="newSwipe">
      <section className="slider">
        <div className="slider__flex">
          <div className="slider__col">
            {/* <div className="slider__prev">Prev</div> */}

            <div className="slider__thumbs">
              <Swiper
                onSwiper={setImagesNavSlider}
                direction="vertical"
                spaceBetween={30}
                slidesPerView={4}
                // slidesPerColumn={2}
                // slidesPerGroup={6}
                // slidesPerColumnFill="row"
                navigation={{
                  nextEl: ".slider__next",
                  prevEl: ".slider__prev",
                }}
                className="swiper-container1"
                breakpoints={{
                  0: {
                    direction: "horizontal",
                  },
                  768: {
                    direction: "vertical",
                  },
                }}
                modules={[Navigation, Thumbs]}
              >
                {slides.map((slide, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="slider__image">
                        <Img
                          src={slide}
                          alt=""
                          hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                          height={"100%"}
                          width={"100%"}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>

            {/* <div className="slider__next">Next</div> */}
          </div>

          <div className="slider__images">
            <Swiper
              thumbs={{
                swiper:
                  imagesNavSlider && !imagesNavSlider.destroyed
                    ? imagesNavSlider
                    : null,
              }}
              direction="horizontal"
              slidesPerView={1}
              spaceBetween={10}
              mousewheel={true}
              navigation={{
                nextEl: ".slider__next",
                prevEl: ".slider__prev",
              }}
              breakpoints={{
                0: {
                  direction: "horizontal",
                },
                768: {
                  direction: "horizontal",
                },
              }}
              className="swiper-container2"
              modules={[Navigation, Thumbs, Mousewheel]}
            >
              {slides.map((slide, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="slider__image">
                      <Img
                        src={slide}
                        alt=""
                        hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                        height={"100%"}
                        width={"100%"}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
}
