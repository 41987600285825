import React, { useEffect, useState } from "react";
import "./water-features.css";
import FloatingNavigation from "../floatingNav";
import { waterFeaturesContent } from "../../constants/data";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";

import ThumbSwipe from "../thumb-swipe";

const WaterFeatures = () => {
  const [productsData, setProductsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "products"]{ waterFeaturesTab {..., image->}}`)
      .then((data) => {
        setProductsData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("products data: ", productsData);
  }, [productsData]);
  return (
    <>
      <section className="features">
        <div className="content">
          <section className="textContentWrapper">
            <div className="header">
              {/* <h1>{productsData?.waterFeaturesTab?.waterFeaturesTitle}</h1> */}
              <h1>{waterFeaturesContent.waterFeaturesTitle}</h1>
            </div>
            <div className="txtContent">
              {/* <p>{productsData?.waterFeaturesTab?.waterFeaturesDetails}</p> */}
              <p>{waterFeaturesContent.waterFeaturesDetails}</p>
            </div>
            <div className="featureSwipeWrapper">
              <ThumbSwipe />
            </div>
          </section>
        </div>
        <div className="floatingNav">
          <FloatingNavigation />
        </div>
      </section>
    </>
  );
};

export default WaterFeatures;
