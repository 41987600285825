import React from "react";
import Skeleton from "react-loading-skeleton";

const CardSkeleton = (cards) => {
  return Array(cards)
    .fill(0)
    .map((item, index) => (
      <div className="card-skeleton" key={index}>
        <div className="heading">
          <Skeleton width={40} height={20} />
        </div>
        <div className="text">
          <Skeleton count={3} />
        </div>
        <div className="link">
          <Skeleton width={20} height={10} />
        </div>
      </div>
    ));
};

export default CardSkeleton;
