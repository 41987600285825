import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import "./products.css";
import { ProductsTabPanel, ScrollToTop, GetInTouch } from "../../components";

const Products = () => {
  // const [value, setValue] = React.useState(2);
  const { productsTab } = useParams();
  const productsLocation = useLocation();

  useEffect(() => {
    document.title = "Products";
  }, []); // Update the document title when the component mounts

  // Determine the active tab index based on the URL path
  const getProductsPathTab = () => {
    const path = productsLocation.pathname;
    if (path === "/products/fiberglass-pools") return 0;
    if (path === "/products/water-features") return 1;
    if (path === "/products/sporty-fun") return 2;
    if (path === "/products/winter-summer-covers") return 3;
    if (path === "/products/hayward-line") return 4;
    return 0; // Default to the first tab
  };

  const activeTab = getProductsPathTab(); // Get the active tab index

  return (
    <>
      <Helmet>
        <title>Products - Rosy Recreational Swimming Pool Construction</title>
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Products - Rosy Recreational Swimming Pool Construction"
        />
        <meta property="og:url" content="https://rosyrec.com/products" />
        <meta
          property="og:site_name"
          content="Rosy Recreational Swimming Pool Construction"
        />
      </Helmet>
      <section className="sub-hero products-hero">
        <div className="container">
          <div className="heroLabel">
            <h1>products</h1>
          </div>
        </div>
      </section>
      <section className="products">
        <div className="container">
          <div className="tabsView">
            <ProductsTabPanel activeProductsTab={productsTab} />
          </div>
        </div>
      </section>
      <GetInTouch />
      <ScrollToTop />
    </>
  );
};

export default Products;
