import React from "react";
import "./fiber-glass.css";
import { Link } from "react-router-dom";
import { brochureImg, ytLaptopImg3 } from "../../assets";
import { HiOutlineGlobeEuropeAfrica } from "react-icons/hi2";
import { ScrollToTop } from "../../components";
import { fiberGlassContent } from "./../../constants/data";

const FiberGlass = () => {
  return (
    <>
      <section className="sub-hero">
        <div className="container">
          <div className="heroLabel">
            <h1>fiberglass pool</h1>
            <small>home/fiberglass pools</small>
          </div>
        </div>
      </section>
      <section className="speciality">
        <div className="container">
          <div className="header">
            <h1>{fiberGlassContent.title}</h1>
            <h5>{fiberGlassContent.subTitle}</h5>
          </div>
          <div className="txtContent">
            <p>{fiberGlassContent.p1}</p>
            <p>{fiberGlassContent.p2}</p>
            <p>{fiberGlassContent.p3}</p>
            <p>{fiberGlassContent.p4}</p>
            <p>{fiberGlassContent.p5}</p>
          </div>
        </div>
      </section>
      <section className="why">
        <div className="container">
          <div className="col">
            <div className="header">
              <h2>{fiberGlassContent.whyTitle}</h2>
            </div>
            <div className="list">
              <ul>
                {fiberGlassContent.whyList.map((list, index) => (
                  <li key={index}>{list}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col imgLink">
            <Link to={"/"}>
              <img src={brochureImg} alt="" />
            </Link>
          </div>
        </div>
      </section>
      <section className="yt-laptop">
        <div className="container">
          <div className="imgHolder">
            <Link to={"/"}>
              <img src={ytLaptopImg3} alt="watch on youtube" />
            </Link>
          </div>
        </div>
      </section>
      <section className="getInTouch">
        <div className="container">
          <div className="col icon">
            <HiOutlineGlobeEuropeAfrica className="globe" />
          </div>
          <div className="col txt">
            <div className="contentWrapper">
              <small>have any questions?</small>
              <h3>don't hesitate to contact us any time</h3>
            </div>
          </div>
          <div className="col cta">
            <div className="btnHolder">
              <Link to={"/contact"} className="btn-sea">
                contact
              </Link>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default FiberGlass;
