import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./home.css";
import sanityClient from "../../client";
import { urlFor } from "../../utils/urlFor";
import {
  grandDealer,
  locationIcon,
  mailIcon,
  paradiseVid,
  phoneIcon,
  poolVid,
  topChoiceAwards,
  icon360,
  waterPortal,
  marbleVid,
  waterLoopVid,
  waterPortalVid,
} from "../../assets";
import { homeContent } from "../../constants/data";
import ThumbsGallery from "../../components/thumbs-gallery";
import { Img, ParaView, ScrollToTop, TestimonySlider } from "../../components";
import Skeleton from "react-loading-skeleton";

import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import NewSwipe from "../../components/newSwipe";
import CardSkeleton from "../../components/loadSkeletons/serviceCard";

import HayWaredPdf from "../../documents/Hayward-Pool-Cleaner-Repair-Form-2020-edit.pdf";

const Home = () => {
  const [homeData, setHomeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "home"]`)
      .then((data) => {
        setHomeData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("home data: ", homeData);
  }, [homeData]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>Rosy Recreational Swimming Pool Construction</title>
        <meta property="og:type" content="article" />

        <meta property="og:url" content="https://rosyrec.com" />
        <meta
          property="og:title"
          content="Rosy Recreational Swimming Pool Construction"
        />
        <meta
          property="og:description"
          content="Trust Rosy Recreational to bring your backyard makeover to reality from start to finish. A multiple award winning company with clean timeless designs that will put your pool at center stage."
        />
      </Helmet>
      <section className="panorama">
        <div className="panoWrapper">
          <ParaView />
          <div className="buttonWrapper">
            <div className="drag">
              <Img
                src={icon360}
                alt="360 icon"
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
              />
              click and drag
            </div>
          </div>
        </div>
      </section>
      <section className="outDoorParadise">
        <video autoPlay muted playsInline loop id="bgVid">
          <source src={paradiseVid} type="video/mp4" />
        </video>
      </section>
      <section className="about">
        <div className="container">
          <div className="row">
            <div
              className="col left"
              data-aos="fade-right"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="800"
            >
              <div className="imgWrapper">
                <Link
                  to={
                    "https://www.topchoiceawards.com/winner/rosy-recreational-inc"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img
                    src={topChoiceAwards}
                    hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                    height={"100%"}
                    width={"100%"}
                    alt="Top Choice Awards"
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
            <div className="col center">
              <div className="contentWrapper">
                <div className="header">
                  <h4 data-aos="zoom-in" data-aos-delay="200">
                    {homeContent.aboutHeader || (
                      <Skeleton height={45} width={600} />
                    )}
                  </h4>
                </div>
                <div className="title">
                  <h1 data-aos="zoom-in" data-aos-delay="400">
                    {homeContent.aboutTitle || (
                      <Skeleton height={25} width={200} />
                    )}
                  </h1>
                </div>
                <div
                  className="textContent"
                  data-aos="zoom-in"
                  data-aos-delay="500"
                >
                  <p>
                    {homeContent.aboutDescription || <Skeleton count={12} />}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col right"
              data-aos="fade-left"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="800"
            >
              <div className="imgWrapper">
                <Link
                  to={
                    "https://rosyrec.com/new/wp-content/uploads/2020/03/LA-StyleGuide-Canada-Grand-EN-200316.pdf"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Img
                    src={grandDealer}
                    alt="Grand Dealer"
                    hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                    height={"100%"}
                    width={"100%"}
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="vidWrapper">
              <video autoPlay muted playsInline loop id="waterLoopVid">
                <source src={waterLoopVid} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>
      <section className="services">
        <div className="container">
          <div className="col left">
            <div className="top">
              <div className="header">
                <h4 data-aos="zoom-in" data-aos-delay="200">
                  our
                  <Skeleton height={45} width={200} />
                </h4>
              </div>
              <div className="title">
                <h1 data-aos="zoom-in" data-aos-delay="400">
                  services
                  <Skeleton height={45} width={600} />
                </h1>
              </div>
            </div>
            <div className="bottom">
              <div
                className="vidWrapper"
                data-aos="fade-right"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="400"
              >
                <video autoPlay muted playsInline loop id="poolVid">
                  <source
                    src={poolVid || <Skeleton height={450} width={600} />}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
          <div className="col right">
            <div className="contentWrapper">
              {/* fetching from sanity */}
              {/* {isLoading && <CardSkeleton cards={4} />}
              {homeData?.serviceCards?.map((service, index) => (
                <div
                  className="txtCard"
                  key={"serviceTitle" + index}
                  data-aos="slide-left"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="content">
                    <h3>{service?.serviceTitle}</h3>
                    <p>{service?.serviceDescription}</p>
                    <a href={service.url}>read more</a>
                  </div>
                </div>
              ))} */}
              {/* fetching from sanity ends */}

              {isLoading && <CardSkeleton cards={4} />}
              {homeContent.services.map((service, index) => (
                <div
                  className="txtCard"
                  key={"serviceTitle" + index}
                  data-aos="slide-left"
                  data-aos-anchor-placement="center-bottom"
                >
                  <div className="content">
                    <h3>{service?.serviceTitle}</h3>
                    <p>{service?.serviceDescription}</p>
                    <a href={service.url}>read more</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="fluid">
        <div className="fluidBkg">
          {/* <Simulation /> */}
          <video autoPlay muted playsInline loop id="marbleVid">
            <source src={marbleVid} type="video/mp4" />
          </video>
        </div>

        <div className="container">
          {/* {homeData?.workExperience?.map((count, index) => (
            <div className="counter" key={index}>
              <div className="number" data-aos="zoom-in" data-aos-delay="400">
                {count?.experienceNumber}
              </div>
              <div className="subTitle" data-aos="zoom-in" data-aos-delay="200">
                {count?.experienceTitle}
              </div>
            </div>
          ))} */}
          {homeContent.counter.map((count, index) => (
            <div className="counter" key={index}>
              <div className="number" data-aos="zoom-in" data-aos-delay="400">
                {count?.number}
              </div>
              <div className="subTitle" data-aos="zoom-in" data-aos-delay="200">
                {count?.subTitle}
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="projects">
        <div className="container">
          <div className="top">
            <div className="header">
              <h4 data-aos="zoom-in" data-aos-delay="200">
                our
              </h4>
            </div>
            <div className="title">
              <h1 data-aos="zoom-in" data-aos-delay="400">
                projects
              </h1>
            </div>
          </div>
          <div className="bottom">
            {/* <ThumbsGallery /> */}
            <NewSwipe />
            <div className="info">
              <div className="col btn-flex-end">
                <Link to={"/portfolio"} className="btn-sea">
                  our portfolio
                </Link>
              </div>
              <div className="col">
                <p>
                  {
                    "Trust Rosy Recreational to bring your backyard makeover to reality from start to finish. A multiple award winning company with clean timeless designs that will put your pool at center stage."
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="clients">
        <div className="container">
          <div className="top">
            <div className="header">
              <h4 data-aos="zoom-in" data-aos-delay="200">
                HAYWARD ROBOTIC REPAIR SERVICES <br />
              </h4>
              <p>
                Below is a link to a downloadable/editable repair form. Once
                completed please print, attach a copy of your receipt and drop
                your cleaner off at Backyard Resorts in Cambridge, ON. The
                address is 20 Sheldon Drive. 519-622-1445.
              </p>
            </div>
            {/* <div className="title">
              <h4 data-aos="zoom-in" data-aos-delay="400">
                use any of the forms below
              </h4>
            </div> */}
            <div className="buttonsContainer">
              <div
                className="hor"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                {/* <div className="ver"> */}
                <Link
                  to={HayWaredPdf}
                  className="btn-sea"
                  target="_blank"
                  rel="noreferrer"
                >
                  download our form
                </Link>
                {/* </div> */}
                {/* <div className="ver">
                  <Link
                    to={"/hayward-forms"}
                    className="btn-sea"
                    rel="noreferrer"
                  >
                    fill our online form
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="content">
            <h1 data-aos="zoom-in" data-aos-delay="400">
              {homeContent.questionsHeader}
            </h1>
            <p>{homeContent.qParagraph}</p>
            <a href="/contact" className="btn-sea">
              contact us
            </a>
          </div>
          <div className="vidWrapperPortal">
            <video autoPlay muted playsInline loop id="waterLoopVid">
              <source src={waterPortalVid} type="video/mp4" />
            </video>
          </div>
          {/* <div className="portal">
            <img src={waterPortal} alt="water portal" />
          </div> */}
        </div>
      </section>
      <section className="questions">
        <div className="container">
          {/* <div className="top">
            <div className="content">
              <h1 data-aos="zoom-in" data-aos-delay="400">
                {homeContent.questionsHeader}
              </h1>
              <p>{homeContent.qParagraph}</p>
              <a href="/contact-us" className="btn-sea">
                contact us
              </a>
            </div>
          </div> */}
          <div className="bottom">
            <div className="cards">
              <div className="icon" data-aos="zoom-in" data-aos-delay="400">
                <Img
                  src={locationIcon}
                  alt="icon"
                  hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                  height={"100%"}
                  width={"100%"}
                />
              </div>
              <div className="details" data-aos="zoom-in" data-aos-delay="200">
                <h4>visit us</h4>
                <p>
                  Backyard Resorts <br />
                  20 Sheldon Dr. <br />
                  Cambridge, Ontario
                </p>
              </div>
            </div>
            <div className="cards" data-aos="zoom-in" data-aos-delay="400">
              <div className="icon">
                <Img
                  src={mailIcon}
                  alt="icon"
                  hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                  height={"100%"}
                  width={"100%"}
                  loading="lazy"
                />
              </div>
              <div className="details" data-aos="zoom-in" data-aos-delay="200">
                <h4>email us</h4>
                <p>{"steve@rosyrec.com" || <Skeleton />}</p>
              </div>
            </div>
            <div className="cards" data-aos="zoom-in" data-aos-delay="400">
              <div className="icon">
                <Img
                  src={phoneIcon}
                  alt="icon"
                  hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                  height={"100%"}
                  width={"100%"}
                  loading="lazy"
                />
              </div>
              <div className="details" data-aos="zoom-in" data-aos-delay="200">
                <h4>call us</h4>
                <p>{"519 239-5341 (Steve)" || <Skeleton />}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Home;
