import React, { useEffect, useState } from "react";
import FloatingNavigation from "../floatingNav";
import { haywardContent } from "../../constants/data";
import "./hayward.css";
import { Link } from "react-router-dom";
import { hayWared } from "../../assets";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";

import HayWaredPdf from "../../documents/Hayward-Pool-Cleaner-Repair-Form-2020-edit.pdf";

import AOS from "aos";
import "aos/dist/aos.css";
import Img from "../image";

const Hayward = () => {
  const [productsData, setProductsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "products"]{..., haywardTab, haywardOfferImage {..., image->}}`
      )

      .then((data) => {
        setProductsData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="features">
        <div className="content">
          <section className="textContentWrapper">
            <div className="header">
              {/* <h1>{productsData?.haywardTab?.haywardTitle}</h1> */}
              <h1>{haywardContent.title}</h1>
            </div>
            <div className="vidWrapper">
              <div
                className="ytVideo"
                data-aos="fade-right"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="200"
              >
                <iframe
                  // src={productsData?.haywardTab?.haywardYtLink}
                  src="https://www.youtube.com/embed/_5ntBxZNfho"
                  title="Expert Line &amp; Program"
                  frameborder="0"
                  allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  loading="lazy"
                ></iframe>
              </div>
              <div
                className="vidBkg"
                data-aos="fade-left"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="400"
              />
            </div>
            {/* <div className="cardContent">
              {productsData?.haywardTab?.expertise?.map((card, index) => (
                <div
                  className="card"
                  key={index}
                  data-aos="zoom-in"
                  data-aos-delay={card.delay}
                >
                  <div className="header">
                    <h3>{card.expertiseTitle}</h3>
                  </div>
                  <div className="cardBody">
                    <p>{card.expertiseDetails}</p>
                  </div>
                  <div className="watchLink">
                    <Link to={card.expertiseYtLink} className="btn-sea">
                      watch
                    </Link>
                  </div>
                </div>
              ))}
            </div> */}
            <div className="cardContent">
              {haywardContent.haywardCards.map((card, index) => (
                <div
                  className="card"
                  key={index}
                  data-aos="zoom-in"
                  data-aos-delay={card.delay}
                >
                  <div className="header">
                    <h3>{card.title}</h3>
                  </div>
                  <div className="cardBody">
                    <p>{card.body}</p>
                  </div>
                  <div className="watchLink">
                    <Link to={card.url} className="btn-sea">
                      watch
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="formWrapper">
              <div className="header">
                <h4>{haywardContent.formTitle}</h4>
                {/* <h1>
                  {
                    productsData?.haywardTab?.haywardRepairTitle
                      ?.haywardOfferTitle
                  }
                </h1> */}
                <h1>{haywardContent.formHeader}</h1>
              </div>
              <div className="row">
                <div className="col">
                  {/* <p>
                    {
                      productsData?.haywardTab?.haywardRepairTitle
                        ?.haywardOfferDetails
                    }
                  </p> */}
                  <p>{haywardContent.p1}</p>
                  <Link
                    to={HayWaredPdf}
                    className="btn-sea"
                    target="_blank"
                    rel="noreferrer"
                  >
                    download our form
                  </Link>
                </div>
                <div className="col">
                  {/* <Img
                    src={urlFor(
                      productsData?.haywardTab?.haywardOfferImage?.image?.image
                    )}
                    alt={
                      productsData?.haywardTab?.haywardRepairTitle?.image?.alt
                    }
                    hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                    height={"100%"}
                    width={"100%"}
                  /> */}
                  <Img
                    src={hayWared}
                    alt={haywardContent.formHeader}
                    hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="floatingNav">
          <FloatingNavigation />
        </div>
      </section>
    </>
  );
};

export default Hayward;
