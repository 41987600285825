export const navigationLinks = [
  {
    id: "1",
    name: "about",
    url: "/our-story-services",
  },
  {
    id: "2",
    name: "portfolio",
    url: "/portfolio",
  },
  {
    id: "3",
    name: "products",
    url: "/products",
  },
  {
    id: "4",
    name: "services",
    url: "/services",
  },

  // {
  //   id: "5",
  //   name: "trades login",
  //   url: "/trades-login",
  // },
  {
    id: "6",
    name: "contact us",
    url: "/contact",
  },
];

export const navigation = [
  // {
  //   id: "1",
  //   name: "FAQs",
  //   url: "/faq",
  // },

  {
    id: "2",
    name: "home",
    url: "/",
  },
  {
    id: "3",
    name: "portfolio",
    url: "/portfolio",
  },
  {
    id: "4",
    name: "our story",
    url: "/our-story-services",
  },
  {
    id: "5",
    name: "services",
    url: "/services",
  },
];

export const services = [
  {
    id: "1",
    name: "design & build",
    url: "/services?tab=2",
  },

  {
    id: "2",
    name: "utility",
    url: "/services?tab=3",
  },
  {
    id: "3",
    name: "concrete",
    url: "/services?tab=1",
  },
  {
    id: "4",
    name: "finishing touches",
    url: "/services?tab=0",
  },
];

export const products = [
  {
    id: "1",
    name: "fiberglass pools",
    url: "/products?tab=0",
  },

  {
    id: "2",
    name: "sporting equipment",
    url: "/products?tab=2",
  },
  {
    id: "3",
    name: "water features",
    url: "/products?tab=1",
  },
  {
    id: "4",
    name: "winter & summer pool covers",
    url: "/products?tab=3",
  },
  {
    id: "5",
    name: "hayward line",
    url: "/products?tab=4",
  },
];

export const footerSummary = {
  title: "Rosy Recreational",
  summary:
    "Trust Rosy Recreational to bring your backyard makeover to reality from start to finish. A multiple award winning company with clean timeless designs that will put your pool at center stage.",
};

export const floatingNavService = [
  {
    id: "1",
    name: "finishing touches",
    url: "/services?tab=0",
  },
  {
    id: "2",
    name: "concrete",
    url: "/services?tab=1",
  },
  {
    id: "3",
    name: "utility",
    url: "/services?tab=3",
  },
];

export const floatingNavPoolFeature = [
  {
    id: "1",
    name: "sporting equipments",
    // url: "/products?tab=2",
    url: "https://www.srsmith.com/en-us/",
  },
  {
    id: "2",
    name: "water features",
    // url: "/products?tab=1",
    url: "https://www.hayward-pool.ca/shop/en/canada/waterfeatures",
  },
  {
    id: "3",
    name: "winter  cover",
    // url: "/products?tab=3",
    url: "https://www.lathampool.ca/solid-mesh-pool-covers",
  },
  {
    id: "",
    name: "summer cover",
    // url: "/products?tab=3",
    url: "https://www.coverstarcanada.com",
  },
  {
    id: "5",
    name: "hayward line",
    url: "/products?tab=4",
  },
];
