import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, FreeMode, Navigation, Thumbs } from "swiper";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./thumb-swipe.css";

import {
  coverThumbsImage1,
  coverThumbsImage2,
  coverThumbsImage3,
  coverThumbsImage4,
} from "../../assets";
import Img from "../image";

const imageData = [
  { img: coverThumbsImage1 },
  { img: coverThumbsImage2 },
  { img: coverThumbsImage3 },
  { img: coverThumbsImage4 },
];

const WinterCoverSwipe = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [productsData, setProductsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "products"]{ winterCoversTab, winterCoversSlideImages-> {..., image->}}`
      )
      .then((data) => {
        setProductsData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  return (
    <main>
      <section className="thumbViewSection">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={0}
          navigation={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="biggerPicture"
        >
          {/* {productsData?.winterCoversTab?.winterCoversSlideImages?.map(
            (item, index) => (
              <SwiperSlide className="bigViewHolder" key={index}>
                <Img
                  src={item?.winterCoversSlideImages?.image?.image}
                  alt="pool gallery"
                  hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                  height={"100%"}
                  width={"100%"}
                />
              </SwiperSlide>
            )
          )} */}
          {imageData.map((item, index) => (
            <SwiperSlide className="bigViewHolder" key={index}>
              <Img
                src={item.img}
                alt="pool gallery"
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="thumbnailPictures"
        >
          {imageData.map((item, index) => (
            <SwiperSlide className="thumbViewHolder" key={index}>
              <Img
                src={item.img}
                alt="pool gallery"
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </main>
  );
};

export default WinterCoverSwipe;
