import React, { Suspense, useRef, lazy } from "react";
import { Canvas, extend, useFrame, useThree } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from "three";
import { useLoader } from "react-three-fiber";

extend({ OrbitControls });

function Controls(props) {
  const { camera, gl } = useThree();
  const ref = useRef();
  useFrame(() => ref.current.update());
  return (
    <orbitControls
      ref={ref}
      target={[0, 0, 0]}
      {...props}
      args={[camera, gl.domElement]}
    />
  );
}

export default function ParaView() {
  // const LazyOptimizedDome = React.lazy(() => import("./OptimizedDome"));

  return (
    <Canvas camera={{ position: [0, 0, 0.1] }}>
      <Controls
        enableZoom={false}
        enablePan={false}
        enableDamping
        dampingFactor={0.2}
        autoRotate
        rotateSpeed={-0.5}
      />
      <Suspense fallback={null}>
        <OptimizedDome />
      </Suspense>
    </Canvas>
  );
}

function OptimizedDome() {
  const texture = useLoader(THREE.TextureLoader, "/Swimming-pool-backyard.jpg");

  // Reduce texture size
  texture.minFilter = THREE.LinearFilter;
  texture.maxAnisotropy = 1;

  return (
    <mesh>
      <sphereBufferGeometry attach="geometry" args={[500, 32, 16]} />{" "}
      {/* Lower-poly geometry */}
      <meshBasicMaterial
        attach="material"
        map={texture}
        side={THREE.BackSide}
      />
    </mesh>
  );
}
