import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FiberGlassPools from "../fiberGlassPools";
import WaterFeatures from "../waterFeatures";
import SportAndFun from "../sportyFun";
import Hayward from "../hayward";
import WinterAndSummerCovers from "../winter-summer-covers";

function ProductTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="ProductTabPanel"
      hidden={value !== index}
      id={`simple-ProductTabPanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ProductTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-ProductTabPanel-${index}`,
  };
}

export default function BasicTabs() {
  // const [value, setValue] = React.useState(0);

  const location = useLocation(); // Get the current URL location
  const queryParams = new URLSearchParams(location.search); // Parse the query parameters

  const [value, setValue] = React.useState(Number(queryParams.get("tab")) || 0); // Set the initial tab value based on the query parameter

  const tabsRef = useRef(null); // Create a ref for the Tabs component

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Update the query parameter when the tab value changes
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", value);
    const newURL = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, "", newURL);

    // Scroll to the Tabs component
    tabsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [location, value]);

  return (
    <div ref={tabsRef}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab label="fiberglass pools" {...a11yProps(0)} />
            <Tab label="water features" {...a11yProps(1)} />
            <Tab label="sporty & fun" {...a11yProps(2)} />
            <Tab label="winter & summer covers" {...a11yProps(3)} />
            <Tab label="hayward line" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <ProductTabPanel value={value} index={0}>
          <div className="tabContentContainer">
            <FiberGlassPools />
          </div>
        </ProductTabPanel>
        <ProductTabPanel value={value} index={1}>
          <WaterFeatures />
        </ProductTabPanel>
        <ProductTabPanel value={value} index={2}>
          <SportAndFun />
        </ProductTabPanel>
        <ProductTabPanel value={value} index={3}>
          <WinterAndSummerCovers />
        </ProductTabPanel>
        <ProductTabPanel value={value} index={4}>
          <Hayward />
        </ProductTabPanel>
      </Box>
    </div>
  );
}
