import React, { useEffect, useState } from "react";
import FloatingNavigation from "../floatingNav";
import { winterCoversContent } from "../../constants/data";
import WinterCoverSwipe from "../thumb-swipe/winterCoverSwipe";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";

const WinterAndSummerCovers = () => {
  const [productsData, setProductsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "products"]{ winterCoversTab {..., image->}}`)
      .then((data) => {
        setProductsData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);
  return (
    <>
      <section className="features">
        <div className="content">
          <section className="textContentWrapper">
            <div className="header">
              {/* <h1>{productsData?.winterCoversTab?.winterCoversTitle}</h1> */}
              <h1>{winterCoversContent.title}</h1>
            </div>
            <div className="txtContent">
              {/* <p>{productsData?.winterCoversTab?.winterCoversDetails}</p> */}
              <p>{winterCoversContent.p1}</p>
              <p>{winterCoversContent.p2}</p>
            </div>
            <div className="featureSwipeWrapper">
              <WinterCoverSwipe />
            </div>
            <br />
            <br />
            <div className="listWrapper">
              <div className="header">
                {/* <h2>{productsData?.winterCoversTab?.clearDeckTitle}</h2> */}
                {/* <h5>{productsData?.winterCoversTab?.clearDeckSubTitle}</h5> */}
                <h2>{winterCoversContent.listTitle}</h2>
                <h5>{winterCoversContent.listSub}</h5>
              </div>
              {/* <div className="list">
                <ul>
                  {productsData?.winterCoversTab?.clearDeck?.map(
                    (list, index) => (
                      <li key={index}>{list?.title}</li>
                    )
                  )}
                </ul>
              </div> */}
              <div className="list">
                <ul>
                  {winterCoversContent.listItem.map((list, index) => (
                    // <li key={index}>{list?.title}</li>
                    <li key={index}>{list}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="vidWrapper vid-mb">
              <div
                className="ytVideo"
                data-aos="fade-right"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="200"
              >
                <iframe
                  // src={productsData?.winterCoversTab?.winterCoversYtLink}
                  src="https://www.youtube.com/embed/CWr5yPssmzM"
                  title="winter summer covers"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullscreen
                  loading="lazy"
                ></iframe>
              </div>
              <div
                className="vidBkg"
                data-aos="fade-left"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="400"
              />
            </div>
          </section>
        </div>
        <div className="floatingNav">
          <FloatingNavigation />
        </div>
      </section>
    </>
  );
};

export default WinterAndSummerCovers;
