import React, { useState, useEffect } from "react";
import FloatingNavigation from "../floatingNav";
import { servicesContent } from "../../constants/data";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";

import AOS from "aos";
import "aos/dist/aos.css";
import Img from "../image";
import PoolFeaturesNav from "../floatingNav/PoolFeaturesNav";

const FinishingTouches = () => {
  const [servicesData, setServicesData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "services"]{ finishingTouchesTab {..., image->}}`)
      .then((data) => {
        setServicesData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("services data: ", servicesData);
  }, [servicesData]);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="features">
        <div className="content">
          <section className="textContentWrapper">
            <div
              className="txtContent"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="100"
            >
              <div className="header">
                {/* <h1>{servicesData?.finishingTouchesTab?.ftTitle}</h1> */}
                <h1>{servicesContent.finishingTouchesTab.ftTitle}</h1>
              </div>
              {/* <p>{servicesData?.finishingTouchesTab?.ftDetails}</p> */}
              <p>{servicesContent.finishingTouchesTab.ftDetails}</p>
            </div>
            <div
              className="imgWrapper"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="300"
            >
              {/* <Img
                src={urlFor(servicesData?.finishingTouchesTab?.image?.image)}
                alt={servicesData?.finishingTouchesTab?.image?.image?.alt}
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
              /> */}
              <Img
                src={servicesContent.finishingTouchesTab.image}
                alt={servicesContent.finishingTouchesTab.ftTitle}
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
              />
            </div>
            <div
              className="txtContent"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="500"
            >
              <div className="header">
                {/* <h1>{servicesData?.finishingTouchesTab?.forYearsTitle}</h1> */}
                <h1>{servicesContent.finishingTouchesTab.forYearsTitle}</h1>
              </div>
              {/* <p>{servicesData?.finishingTouchesTab?.forYearsDetails}</p> */}
              <p>{servicesContent.finishingTouchesTab.forYearsDetails}</p>
            </div>
            <div
              className="txtContent"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-delay="700"
            >
              <div className="header">
                {/* <h1>
                  {servicesData?.finishingTouchesTab?.dreamToRealityTitle}
                </h1> */}
                <h1>
                  {servicesContent.finishingTouchesTab.dreamToRealityTitle}
                </h1>
              </div>
              {/* <p>{servicesData?.finishingTouchesTab?.dreamToRealityDetails}</p> */}
              <p>{servicesContent.finishingTouchesTab.dreamToRealityDetails}</p>
            </div>
          </section>
        </div>
        <div className="floatingNav">
          <PoolFeaturesNav />
        </div>
      </section>
    </>
  );
};

export default FinishingTouches;
