import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { brochureImg, ytLaptopImg3 } from "../../assets";
import { fiberGlassContent } from "../../constants/data";
import "./fiber-glass-pools.css";
// import sanityClient from "../../client";
// import Skeleton from "react-loading-skeleton";
// import { urlFor } from "../../utils/urlFor";

import Pdf from "../../documents/Rosy-Recreational-Latham-Grand-Dealer-Fiberglass-Catalog-2022-.pdf";
import shapesPDF from "../../documents/2023_CAN_GRA_D_FG_Offering_v2.pdf";

import AOS from "aos";
import "aos/dist/aos.css";
import Img from "../image";

const FiberGlassPools = () => {
  const [productsData, setProductsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   sanityClient
  //     // .fetch(
  //     //   `*[_type == "products"]{.., fiberglassTab, lathamImage-> {..., image->}}`
  //     // )
  //     .fetch(`*[_type == "products"]{.., fiberglassTab{lathamImage->}}`)
  //     .then((data) => {
  //       setProductsData(data[0]);
  //       setIsLoading(false);
  //     })
  //     .catch(console.error);
  // }, []);

  // useEffect(() => {
  //   console.log("products data: ", productsData);
  // }, [productsData]);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="speciality">
        <div className="col textContentCol">
          <div className="header">
            {/* <h1>{productsData?.fiberglassTab?.fgTitle}</h1>
            <h5>{productsData?.fiberglassTab?.fgSubTitle}</h5> */}
            <h1>{fiberGlassContent.title}</h1>
            <h5>{fiberGlassContent.subTitle}</h5>
          </div>
          <div className="txtContent">
            {/* <p>{productsData?.fiberglassTab?.fgDetails}</p> */}
            <p>{fiberGlassContent.p1}</p>
            <p>{fiberGlassContent.p2}</p>
            <p>{fiberGlassContent.p3}</p>
            <p>{fiberGlassContent.p4}</p>
            <p>{fiberGlassContent.p5}</p>
          </div>
        </div>
        <div className="col imgContentCol">
          <div className="imgWrapper">
            <Img
              src={fiberGlassContent.specialityImg}
              alt=""
              hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
              height={"100%"}
              width={"100%"}
            />
          </div>
        </div>
      </section>
      <section className="banner" data-aos="zoom-in" data-aos-delay="200">
        <div className="background" />
        <div className="container">
          <div className="imgWrapper">
            <Link to={shapesPDF} target="_blank" rel="noreferrer">
              <Img
                src={fiberGlassContent.banner}
                // alt={productsData?.fiberglassTab?.image?.alt}
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={"100%"}
                width={"100%"}
              />
            </Link>
          </div>
        </div>
      </section>
      <section className="why">
        <div
          className="col"
          data-aos="fade-right"
          data-aos-anchor-placement="top-bottom"
          data-aos-delay="800"
        >
          <div className="header">
            <h2>{fiberGlassContent.whyTitle}</h2>
          </div>
          <div className="list">
            <ul>
              {fiberGlassContent.whyList.map((list, index) => (
                <li key={index}>{list}</li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className="col imgLink"
          data-aos="fade-left"
          data-aos-anchor-placement="top-bottom"
          data-aos-delay="800"
        >
          <Link to={Pdf} target="_blank" rel="noreferrer">
            <Img
              src={fiberGlassContent.whyBooklet}
              alt=""
              hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
              height={100}
              width={100}
            />
          </Link>
        </div>
      </section>
      {/* <section className="yt-laptop">
        <div className="container">
          <div className="imgHolder">
            <Link to={"https://youtu.be/116Ss7yURMg"} target="_blank">
              <Img
                src={ytLaptopImg3}
                alt="watch on youtube"
                hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                height={100}
                width={100}
              />
            </Link>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default FiberGlassPools;
