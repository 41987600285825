import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  ContactUs,
  FiberGlass,
  HaywardForms,
  Home,
  NotFoundPage,
  OurStoryServices,
  Portfolio,
  Products,
  Services,
} from "./pages";

import { SkeletonTheme } from "react-loading-skeleton";
import { Footer, Navbar } from "./components";

//     <SkeletonTheme baseColor="#313131" highlightColor="#525252">

function App() {
  return (
    <SkeletonTheme baseColor="#d7d7d7" highlightColor="#bcbcbc">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/fiberglass" element={<FiberGlass />} />

          <Route path="/products" element={<Products />} />
          <Route path="/products/:productsTab" element={<Products />} />

          <Route path="/services" element={<Services />} />
          <Route path="/services/:serviceTab" element={<Services />} />

          <Route path="/our-story-services" element={<OurStoryServices />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/hayward-forms" element={<HaywardForms />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </SkeletonTheme>
  );
}

export default App;
