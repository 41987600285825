import React, { useState, useEffect } from "react";
import "./testimonials.css";
import { quotation } from "./../../assets";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";

import { testimonies } from "../../constants/data";

const Testimonials = () => {
  const [testimonyData, setTestimonyData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "testimonies"]
        {...,  awardsBanner->,  qualityFirstImage->, whereWeWorkImage->,  teamMembers[] {..., image->}}`
      )
      .then((data) => {
        setTestimonyData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("testimony data: ", testimonyData);
  }, [testimonyData]);

  return (
    <section id="testimonials" className="testimonials">
      <div className="content">
        <div className="header">
          <h5>{testimonies.testimonyHeader}</h5>
          <h1>{testimonies.testimonyTitle}</h1>
        </div>
        <Swiper
          className="container testimonials__container" // install Swiper modules
          modules={[Pagination]}
          spaceBetween={40}
          pagination={{ clickable: true }}
          breakpoints={{
            250: {
              slidesPerView: 1,
            },
            450: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {/* {testimonyData?.testimonyList?.map((person, index) => {
            return (
              <SwiperSlide key={"testifier#" + index} className="testimonial">
                <div className="quotation">
                  <img src={quotation} alt="quotation mark" loading="lazy" />
                </div>

                <p className="client__review">{person.testimony}</p>
                <h3 className="client__name">{person.testifier}</h3>
                <h6>{person.role}</h6>
              </SwiperSlide>
            );
          })} */}
          {testimonies.testimonials.map((person, index) => {
            return (
              <SwiperSlide key={"testifier#" + index} className="testimonial">
                <div className="quotation">
                  <img src={quotation} alt="quotation mark" loading="lazy" />
                </div>

                <p className="client__review">{person.testimony}</p>
                <h3 className="client__name">{person.testifier}</h3>
                <h6>{person.role}</h6>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
