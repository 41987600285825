import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "./portfolio.css";
import {
  ScrollToTop,
  PortfolioGallery,
  GetInTouch,
  QuoteForm,
} from "../../components";
// import ImageViewer from "../../components/imageViewer/ImageViewer";
// import ImageViewApp from "../../components/imageViewer";
import { galleryImages } from "../../constants/data";
import sanityClient from "../../client";
import Skeleton from "react-loading-skeleton";
import { urlFor } from "../../utils/urlFor";
import Gallery from "../../components/portfolioGallery/Gallery";
import ImagesGallery from "../../components/portfolioGallery/Gallery";

const Portfolio = () => {
  const [portfolioData, setPortfolioData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const descriptionExists = () =>
    portfolioData?.hasOwnProperty("quoteDescription");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "portfolio"]{...,  awardsBanner->,  qualityFirstImage->, portfolioImages->,  teamMembers[] {..., image->}}`
      )
      .then((data) => {
        setPortfolioData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);

    sanityClient
      .fetch("*[_type == 'gallery']")
      .then((data) => {
        console.log("gallery data: ", data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    console.log("about data: ", portfolioData);
  }, [portfolioData]);

  return (
    <>
      <Helmet>
        <title>Portfolio - Rosy Recreational Swimming Pool Construction</title>
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Portfolio - Rosy Recreational Swimming Pool Construction"
        />
        <meta property="og:url" content="https://rosyrec.com/portfolio/" />
        <meta
          property="og:site_name"
          content="Rosy Recreational Swimming Pool Construction"
        />
      </Helmet>
      <section className="sub-hero portfolioPage">
        <div className="container">
          <div className="heroLabel">
            <h1>Portfolio</h1>
          </div>
        </div>
      </section>
      {/* <section className="getQuote">
        <div className="container">
          <div className="quoteWrapper">
            <div className="hor">
              <div className="ver">
                <div className="header">
                  <h1>{portfolioData?.quoteHeaderTitle}</h1>
                  <div className="txtContent">
                    <p>{portfolioData?.quoteDescription}</p>
                  </div>
                </div>
              </div>
              <div className="ver">
                <div className="formHolder">
                  <QuoteForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className=" portfolioSection">
        <div className="container">
          <div className="content">
            <div className="header">
              <h1>{portfolioData?.galleryTitle}</h1>
            </div>
          </div>

          <div className="workImages">
            {/* <PortfolioGallery galleryImages={galleryImages} /> */}
            <ImagesGallery />
          </div>
        </div>
      </section>
      <GetInTouch />
      <ScrollToTop />
    </>
  );
};

export default Portfolio;
