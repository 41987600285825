import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import "./services.css";
import { GetInTouch, ScrollToTop, ServiceTabPanel } from "../../components";

const Services = () => {
  // const [value, setValue] = React.useState(2);
  const { serviceTab } = useParams();
  const location = useLocation();

  useEffect(() => {
    document.title = "Services";
  }, []); // Update the document title when the component mounts

  // Determine the active tab index based on the URL path
  const getServicePathTab = () => {
    const path = location.pathname;
    if (path === "/services/finishing-touches") return 0;
    if (path === "/services/concrete") return 1;
    if (path === "/services/design-and-build") return 2;
    if (path === "/services/utility") return 3;
    return 0; // Default to the first tab
  };

  const activeServiceTab = getServicePathTab(); // Get the active tab index

  return (
    <>
      <Helmet>
        <title>Services - Rosy Recreational Swimming Pool Construction</title>
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Services - Rosy Recreational Swimming Pool Construction"
        />
        <meta property="og:url" content="https://rosyrec.com/services" />
        <meta
          property="og:site_name"
          content="Rosy Recreational Swimming Pool Construction"
        />
      </Helmet>
      <section className="sub-hero servicesPage">
        <div className="container">
          <div className="heroLabel">
            <h1>services</h1>
          </div>
        </div>
      </section>
      <section className="products">
        <div className="container">
          <div className="tabsView">
            <ServiceTabPanel activeServiceTab={serviceTab} />
          </div>
        </div>
      </section>
      <GetInTouch />
      <ScrollToTop />
    </>
  );
};

export default Services;
