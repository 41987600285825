import React, { useState } from "react";
import "./floating-nav.css";
import { Link } from "react-router-dom";
import {
  floatingNavService,
  floatingNavPoolFeature,
} from "../../constants/links";

const FloatingNavigation = () => {
  const [isNavToggle, setIsNavToggle] = useState(false);
  const toggle = () => setIsNavToggle(!isNavToggle);
  return (
    <>
      <div className="navWrapper">
        <div className="header">
          <h1>services</h1>
        </div>
        {floatingNavService.map((links) => {
          return (
            <Link
              key={links.id}
              to={links.url}
              className={({ isActive }) => (isActive ? "active" : "menu_item")}
              onClick={toggle}
            >
              {links.name}
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default FloatingNavigation;
