import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, QuoteForm, ScrollToTop } from "../../components";
import emailjs from "@emailjs/browser";
import "./contact.css";
import { contactUsContent, ourStoryContent } from "../../constants/data";
import sanityClient from "../../client";
// import Skeleton from "react-loading-skeleton";
// import { urlFor } from "../../utils/urlFor";

import AOS from "aos";
import "aos/dist/aos.css";
import { callIcon, emailIcon, exclamationIcon, mapIcon } from "../../assets";
import { Link } from "react-router-dom";

const ContactUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const form = useRef();

  const initialState = {
    name: "",
    email: "",
    message: "",
  };

  const [{ name, email, message }, setState] = useState(initialState);

  const [statusMessage, setStatusMessage] = useState("");
  const [contactData, setContactData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const sendEmail = (e) => {
    e.preventDefault();
    //console.log(name, email, message);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text, result.status);
          clearState();
          setStatusMessage("Message sent! We'll get back to you shortly.");
        },
        (error) => {
          console.log(error.text);
          setStatusMessage(
            `${error.text} Couldn't send. Check your network connection`
          );
        }
      );
    e.target.reset();
  };

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "contact"]`)
      .then((data) => {
        setContactData(data[0]);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact - Rosy Recreational Swimming Pool Construction</title>
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Contact - Rosy Recreational Swimming Pool Construction"
        />
        <meta property="og:url" content="https://rosyrec.com/contact" />
        <meta
          property="og:site_name"
          content="Rosy Recreational Swimming Pool Construction"
        />
      </Helmet>
      <section className="sub-hero contact-us">
        <div className="container">
          <div className="heroLabel">
            {/* <h1>{contactData?.headerTitle}</h1> */}
            <h1>{contactUsContent.headerTitle}</h1>
          </div>
        </div>
      </section>
      {/* <section className="getQuote">
        <div className="container">
          <div className="quoteWrapper">
            <div className="hor">
              <div className="ver">
                <div className="header">
                  <h1>GET A QUOTE</h1>
                  <div className="txtContent">
                    <p>
                      Trust Rosy Recreational to bring your backyard makeover to
                      reality from start to finish. A multiple award winning
                      company with clean timeless designs that will put your
                      pool at center stage.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ver">
                <div className="formHolder">
                  <QuoteForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="contactDetails">
        <div className="container">
          <div
            className="col"
            data-aos="fade-right"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="800"
          >
            <div className="header">
              {/* <h5>{contactData?.subHeaderTitle}</h5> */}
              <h5>{contactUsContent.subHeaderTitle}</h5>
              {/* <h1>{contactData?.headerTitle}</h1> */}
              <h1>{contactUsContent.headerTitle}</h1>
            </div>
            <div className="content">
              <div className="contactInfo">
                <div className="icon">
                  <Img
                    src={emailIcon}
                    alt="email"
                    hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
                <div className="contactInfoDetails">
                  <h5>email us</h5>
                  {/* <p>
                    <a href={`mailto:${contactData?.email}`} className="blue">
                      {contactData?.email}
                    </a>
                  </p> */}
                  <p>
                    <Link className="blue" to="mailto:steve@rosyrec.com">
                      steve@rosyrec.com
                    </Link>
                  </p>
                </div>
              </div>

              <div className="contactInfo">
                <div className="icon">
                  <Img
                    src={callIcon}
                    alt="phone"
                    hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
                <div className="contactInfoDetails">
                  <h5>call us</h5>
                  {/* <p>{contactData?.phone}</p> */}
                  <p>519 239-5341 (Steve)</p>
                </div>
              </div>

              <div className="contactInfo">
                <div className="icon">
                  <Img
                    src={mapIcon}
                    alt="map"
                    hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
                <div className="contactInfoDetails">
                  <h5>visit us</h5>
                  {/* <p>{contactData?.location}</p> */}
                  <p>
                    Backyard Resorts <br />
                    20 Sheldon Dr. <br />
                    Cambridge, Ontario
                  </p>
                </div>
              </div>

              <div className="contactInfo">
                <div className="icon">
                  <Img
                    src={exclamationIcon}
                    alt="note"
                    hash={"LaJku}E4.5ac~VR$InWB_4xVWHt8"}
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
                <div className="contactInfoDetails">
                  {/* <p className="blue">{contactData?.warning}</p> */}
                  <p className="blue">
                    Please check your spam folder if you haven't received a
                    response within 2 business days
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col"
            data-aos="fade-left"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="800"
          >
            <div className="header">
              {/* <h1>{contactData?.areaTitle}</h1>
              <h5>{contactData?.areaSubTitle}</h5> */}
              <h1>{contactUsContent.areaTitle}</h1>
              <h5>{contactUsContent.areaSubTitle}</h5>
            </div>
            <div className="content">
              <div className="map">
                {/* <Img
                  src={urlFor(contactData?.image)}
                  alt={ourStoryContent.whereWeWorkHeader}
                  hash={"LEHV6nWB2yk8pyo0adR*.7kCMdnj"}
                  height={"100%"}
                  width={"100%"}
                /> */}
                <Img
                  src={ourStoryContent.whereWeWorkImg}
                  alt={ourStoryContent.whereWeWorkHeader}
                  hash={"LEHV6nWB2yk8pyo0adR*.7kCMdnj"}
                  height={"100%"}
                  width={"100%"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="contactFormSection">
        <div className="container">
          <div className="contactForm" data-aos="zoom-in" data-aos-delay="100">
            <form ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                name="name"
                placeholder="Your Full Name"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                required
              />
              <textarea
                name="message"
                rows="7"
                placeholder="Your Message"
                required
              ></textarea>

              <button type="submit" className="btn-sea">
                Send Message
              </button>
              <p className="alertMsg">{statusMessage}</p>
            </form>
          </div>
        </div>
      </section> */}

      <ScrollToTop />
    </>
  );
};

export default ContactUs;
