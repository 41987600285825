import React, { useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./forms.css";
import { ScrollToTop } from "../../components";

const HaywardForms = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [vacModel, setVacModel] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [purchasedForm, setPurchasedForm] = useState("");
  const [dateDropOff, setDateDropOff] = useState("");
  const [warranty, setWarranty] = useState("");
  const [complaint, setComplaint] = useState([]);
  const [repair, setRepair] = useState("");
  const [repairPartsUsed, setRepairPartsUsed] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");

  const handleRadioChange = (event) => {
    setWarranty(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a FormData object
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("homePhone", homePhone);
    formData.append("vacModel", vacModel);
    formData.append("serialNumber", serialNumber);
    formData.append("purchasedForm", purchasedForm);
    formData.append("dateDropOff", dateDropOff);
    formData.append("warranty", warranty);
    formData.append("complaint", complaint);
    formData.append("repair", repair);
    formData.append("repairPartsUsed", repairPartsUsed);
    formData.append("pickupDate", pickupDate);
    formData.append("receiptNumber", receiptNumber);

    // // Send the form data to the PHP backend
    // fetch("http://127.0.0.1/api/rosyrec", {
    //   method: "POST",
    //   body: formData, // Send the FormData object
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data.message); // Handle the response from the backend

    //     if (data.success) {
    //       // Show a success message to the user
    //       alert("Form submitted successfully!");

    //       // Redirect to the payment page after successful form submission
    //       window.location.href = "/hayward-forms";
    //     } else {
    //       // Show a failure message to the user
    //       alert("Form submission failed. Please try again.");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
    // Send the form data to the PHP backend using Axios
    axios
      .post("http://127.0.0.1/api/rosyrec", formData)
      .then((response) => {
        console.log(response.data.message); // Handle the response from the backend

        if (response.data.success) {
          // Show a success message to the user
          alert("Form submitted successfully!");

          // Redirect to the payment page after successful form submission
          window.location.href = "/hayward-forms";
        } else {
          // Show a failure message to the user
          alert("Form submission failed. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Helmet>
        <title>
          Hayward forms - Rosy Recreational Swimming Pool Construction
        </title>
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Hayward forms - Rosy Recreational Swimming Pool Construction"
        />
        <meta property="og:url" content="https://rosyrec.com/hayward-forms" />
        <meta
          property="og:site_name"
          content="Rosy Recreational Swimming Pool Construction"
        />
      </Helmet>
      <section className="formsSection">
        <div className="container">
          <div className="header">
            <h2>Hayward Pool Vacuum Repair Form </h2>
            <p>Flat Rate Repair Charge: $85 + HST | Parts Quoted Separately</p>
          </div>
          <div className="formWrapper">
            <form onSubmit={handleSubmit}>
              <div className="formGroup">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="formGroup">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Home Phone"
                  required
                  value={homePhone}
                  onChange={(e) => setHomePhone(e.target.value)}
                />
              </div>
              <div className="formGroup">
                <input
                  type="text"
                  name="vacModel"
                  placeholder="Vac Model"
                  required
                  value={vacModel}
                  onChange={(e) => setVacModel(e.target.value)}
                />
                <input
                  type="text"
                  name="serialNumber"
                  placeholder="Serial Number"
                  required
                  value={serialNumber}
                  onChange={(e) => setSerialNumber(e.target.value)}
                />
              </div>
              <div className="formGroup">
                <input
                  type="text"
                  name="purchasedForm"
                  placeholder="Purchased Form"
                  required
                  value={purchasedForm}
                  onChange={(e) => setPurchasedForm(e.target.value)}
                />
                <input
                  type="datetime"
                  name="dateDropOff"
                  placeholder="Date Drop Off"
                  required
                  value={dateDropOff}
                  onChange={(e) => setDateDropOff(e.target.value)}
                />
              </div>
              <div className="formCheckBox">
                <label for="warranty"> Warranty?</label>
                <div className="checkBoxes radioButtons">
                  <label>
                    <input
                      type="radio"
                      name="warranty"
                      value="Yes"
                      checked={warranty === "Yes"}
                      onChange={handleRadioChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="warranty"
                      value="No"
                      checked={warranty === "No"}
                      onChange={handleRadioChange}
                    />
                    No
                  </label>
                </div>
              </div>
              <div className="description">
                <textarea
                  name="complaint"
                  id="description"
                  cols="30"
                  rows="10"
                  placeholder="Complaint"
                  required
                  value={complaint}
                  onChange={(e) => setComplaint(e.target.value)}
                ></textarea>
              </div>

              <div className="formSingle">
                <input
                  type="text"
                  name="repair"
                  placeholder="Repair"
                  required
                  value={repair}
                  onChange={(e) => setRepair(e.target.value)}
                />
              </div>
              <div className="formSingle">
                <input
                  type="text"
                  name="repair_parts"
                  placeholder="Repair Parts Used"
                  required
                  value={repairPartsUsed}
                  onChange={(e) => setRepairPartsUsed(e.target.value)}
                />
              </div>

              <div className="formGroup">
                <input
                  type="datetime"
                  name="date_pickup"
                  placeholder="Date Pick Up"
                  required
                  value={pickupDate}
                  onChange={(e) => setPickupDate(e.target.value)}
                />
                <input
                  type="number"
                  name="receiptNumber"
                  placeholder="Receipt Number"
                  required
                  value={receiptNumber}
                  onChange={(e) => setReceiptNumber(e.target.value)}
                />
              </div>

              <input type="submit" value="Send" className="btn-sea" />
            </form>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default HaywardForms;
