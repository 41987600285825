import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineGlobeEuropeAfrica } from "react-icons/hi2";
import "./get-in-touch.css";

const GetInTouch = () => {
  return (
    <section className="getInTouch">
      <div className="container">
        <div className="col icon">
          <HiOutlineGlobeEuropeAfrica className="globe" />
        </div>
        <div className="col txt">
          <div className="contentWrapper">
            <small>have any questions?</small>
            <h3>don't hesitate to contact us any time</h3>
          </div>
        </div>
        <div className="col cta">
          <div className="btnHolder">
            <Link to={"/contact"} className="btn-sea">
              contact
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
